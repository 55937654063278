import { Injectable } from '@angular/core';
import * as CryptoJS from 'crypto-js';

@Injectable({
    providedIn: 'root'
})
export class StorageService {

    private cryptoKey = '#{cryptoJsKey}#';
    currentUser = '#{currentUser}#';

    setLocalStorage(nombre: string, data: string) {
        localStorage.removeItem(nombre);
        const ciphertext = CryptoJS.AES.encrypt(
            JSON.stringify(data),
            this.cryptoKey
        );
        localStorage.setItem(nombre, ciphertext.toString());
    }

    getLocalStorage(nombre: string) {
        const localStorageItem = localStorage.getItem(nombre);
        if (localStorageItem != null) {
            const bytes = CryptoJS.AES.decrypt(
                localStorageItem,
                this.cryptoKey
            );
            const decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
            return JSON.parse(decryptedData);
        }
    }

    removeItem(nombre: string) {
        if (localStorage.getItem(nombre) != null) {
            localStorage.removeItem(nombre);
        }
    }

}
