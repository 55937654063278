import { CUSTOM_ELEMENTS_SCHEMA, LOCALE_ID, NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { HeaderBcComponent } from './shared/commons/header-bc/header-bc.component';
import { BcSharedModule } from './shared/bc-shared/bc-shared.module';
import { BcServicesModule } from '@bancolombia/design-system-web/bc-services';
import { CommonModule, registerLocaleData } from '@angular/common';
import { CoreModule } from './core/core.module';
import { FooterBcComponent } from './shared/commons/footer-bc/footer-bc.component';
import { NgxSpinnerModule } from 'ngx-spinner';
import localeEsCo from '@angular/common/locales/es-CO';

//MSAL-ANGULAR
import { IPublicClientApplication, PublicClientApplication, InteractionType, BrowserCacheLocation, LogLevel } from '@azure/msal-browser';
import {
    MsalGuard, MsalInterceptor, MsalBroadcastService, MsalInterceptorConfiguration, MsalService, MSAL_GUARD_CONFIG,
    MSAL_INSTANCE, MSAL_INTERCEPTOR_CONFIG, MsalGuardConfiguration
} from '@azure/msal-angular';
import { HTTP_INTERCEPTORS } from '@angular/common/http';

import { TokenInterceptor } from './core/interceptors/tokenInterceptor';
import { msal } from 'src/environments/environment';

registerLocaleData(localeEsCo, 'es-CO');

const isIE = window.navigator.userAgent.indexOf("MSIE ") > -1 || window.navigator.userAgent.indexOf("Trident/") > -1;

export function msalInstanceFactory(): IPublicClientApplication {
    return new PublicClientApplication({
        auth: {
            clientId: msal.clientId,
            authority: `https://login.microsoftonline.com/${msal.tenant}`, 
            redirectUri: msal.redirectUri,
            postLogoutRedirectUri: '/'
        },
        cache: {
            cacheLocation: BrowserCacheLocation.SessionStorage,
            storeAuthStateInCookie: isIE, // set to true for IE 11
        },
        system: {
            loggerOptions: {
                logLevel: LogLevel.Info,
                piiLoggingEnabled: false
            }
        }
    });
}

export function msalInterceptorConfigFactory(): MsalInterceptorConfiguration {
    const protectedResourceMap = new Map<string, Array<string>>();
    protectedResourceMap.set('https://graph.microsoft.com/v1/me', ['user.read']);
    return {
        interactionType: InteractionType.Popup,
        protectedResourceMap
    };
}

export function msalGuardConfigFactory(): MsalGuardConfiguration {
    return { interactionType: InteractionType.Popup };
}

@NgModule({
    declarations: [
        AppComponent,
        HeaderBcComponent,
        FooterBcComponent
    ],
    imports: [
        CommonModule,
        BrowserModule,
        AppRoutingModule,
        BrowserAnimationsModule,
        BcSharedModule,
        CoreModule,
        NgxSpinnerModule.forRoot({ type: 'ball-clip-rotate' }),
        BcServicesModule.forRoot()
    ],
    providers: [
        {
            provide: LOCALE_ID,
            useValue: "es-CO"
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: TokenInterceptor,
            multi: true
        },
        {
            provide: HTTP_INTERCEPTORS,
            useClass: MsalInterceptor,
            multi: true
        },
        {
            provide: MSAL_INSTANCE,
            useFactory: msalInstanceFactory
        },
        {
            provide: MSAL_GUARD_CONFIG,
            useFactory: msalGuardConfigFactory
        },
        {
            provide: MSAL_INTERCEPTOR_CONFIG,
            useFactory: msalInterceptorConfigFactory
        },
        MsalService,
        MsalGuard,
        MsalBroadcastService,
    ],
    exports: [
        AppComponent,
        HeaderBcComponent,
        FooterBcComponent
    ],
    bootstrap: [AppComponent],
    schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule { }
