import { Component, Inject } from '@angular/core';
import { Router } from '@angular/router';
import { MsalGuardConfiguration, MsalService, MSAL_GUARD_CONFIG } from '@azure/msal-angular';
import { PopupRequest } from '@azure/msal-browser';
import Menu from '../assets/jsons/Menu.json';
import { StorageService } from './core/service/storage.service';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.css']
})
export class AppComponent {

    title = 'NU0418001_Segemple_Front';
    isIframe = false;
    isloggedIn = false;
    menuDinamic = Menu?.menu;
    isLoginButtonHidden: boolean = true;

    logout = () => {
        this.authenticationService.logoutRedirect();
        this.clearLocalStorage();
    }

    constructor(
        @Inject(MSAL_GUARD_CONFIG) public msalGuardConfig: MsalGuardConfiguration,
        private authenticationService: MsalService,
        private storageService: StorageService,
        private router: Router,
    ) { }

    ngOnInit() {
      this.isIframe = window !== window.parent && !window.opener;
      this.setLoginDisplay();
    }

    loginPopup() {
        this.isLoginButtonHidden = false;
        const popupRequest = this.msalGuardConfig.authRequest ?
                                { ...this.msalGuardConfig.authRequest } as PopupRequest : undefined;
        this.authenticationService.loginPopup(popupRequest)
            .subscribe({
                next: (response: any) => {
                    this.loginSubscribe(response);
                },
                error: (error) => {
                    this.isLoginButtonHidden = true;
                }
            });
    }

    loginSubscribe(response: any) {
        this.authenticationService.instance.setActiveAccount(response.account);
        this.setLoginDisplay();

        if (this.isloggedIn) {
            this.clearLocalStorage();
            let networkUser: string = response.account.username;
            networkUser = networkUser.split('@')[0].toUpperCase();
            const user = {
                user: networkUser,
                email: response.account.username,
                name: response.account.name,
            };
            this.storageService.setLocalStorage(this.storageService.currentUser, JSON.stringify(user));
            this.storageService.setLocalStorage('birthday', JSON.stringify(false));
            this.router.navigate(['inicio']);
        }
    }

    setLoginDisplay() {
        this.isloggedIn = this.authenticationService.instance.getAllAccounts().length > 0;
    }

    clearLocalStorage() {
        if (localStorage.getItem(this.storageService.currentUser))
        {
          localStorage.removeItem(this.storageService.currentUser);
        }
    }
}


