import { Component, OnInit } from '@angular/core';
import { StorageService } from 'src/app/core/service/storage.service';

@Component({
  selector: 'app-header-bc',
  templateUrl: './header-bc.component.html',
  styleUrls: ['./header-bc.component.css']
})
export class HeaderBcComponent implements OnInit {

  name: string;

  constructor(private storageService: StorageService) { }

  ngOnInit(): void {
    this.name = this.storageService.getLocalStorage(this.storageService.currentUser)?.name;
  }
}
