<bc-header [menuInteraction]="true">
    <bc-header-left logoName="grupo-bancolombia-horizontal" channelName="Portal Seguros para Empleados">
    </bc-header-left>

    <bc-header-list>
        <bc-float-menu>
            <div class="bc-float-menu-toggle bc-icon bc-flex bc-align-items-center">
                    <em class="bc-icon bc-sm">user</em>
                    <div class="bc-header-nav-item-text">
                        <small>{{name}}</small>
                    </div>
            </div>
        </bc-float-menu>
        <!-- <bc-header-item id="bc-header-item-help" itemtype="user" icon="help" text="Ayuda"></bc-header-item> -->

    </bc-header-list>
</bc-header>
