import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { endpoint } from 'src/environments/environment';
import { Observable, map } from 'rxjs';
import CotizacionRequestDto from '../dto/cotizacion-request.dto';
import CotizacionResponseDto from '../dto/cotizacion-response.dto';
import ListResponse from '../dto/list-response.dto';
import Response from '../dto/response.dto';
import { AseguradoraPlanModel } from '../model/AseguradoraPlan.model';
import CotizacionRequestLifeDto from '../dto/cotizacion-request-life.dto';
import CotizacionRequestAssistanceDto from '../dto/cotizacion-request-assistance.dto';

@Injectable({
  providedIn: 'root'
})
export class QuotationService {

  private endpointQuotation: string;
  private endpointQuotationLife: string;
  private endpointQuotationAssistance: string;
  private endpointExpeditionAssistance: string;
  private endpointPlans: string;
  private limitMonthAndDay: string = '07-13';

  constructor(private http: HttpClient) {
    this.endpointQuotation = endpoint.cotizacion;
    this.endpointPlans = endpoint.planes;
    this.endpointQuotationLife = endpoint.cotizacionLife;
    this.endpointQuotationAssistance = endpoint.cotizacionAsistencia;
    this.endpointExpeditionAssistance =endpoint.expedicionAsistencia;}

  getAllPlans(): Observable<Array<AseguradoraPlanModel>> {
    return this.http.get<ListResponse<AseguradoraPlanModel>>(this.endpointPlans)
      .pipe(map((res: ListResponse<AseguradoraPlanModel>) => { return res.data }));
  }

  getQuotation(request: CotizacionRequestDto): Observable<CotizacionResponseDto> {
    const current = new Date();
    const limit = new Date(`${(new Date().getFullYear())}-${this.limitMonthAndDay}`);
    const limitYear = new Date().getFullYear() + ((current > limit) ? 1 : 0);
    request.fechaFinVigencia = `${limitYear}-${this.limitMonthAndDay}`;


    return this.http.post<Response<CotizacionResponseDto>>(this.endpointQuotation, request,)
      .pipe(map((res: Response<CotizacionResponseDto>) => { return res.data }));
  }

  getQuotationLife(request: CotizacionRequestLifeDto): Observable<any> {

    return this.http.post<Response<any>>(this.endpointQuotationLife, request,)
      .pipe(map((res: Response<any>) => { return res.data }));
  }

  getQuotationAssitance(): Observable<any> {

    return this.http.get<Response<any>>(`${this.endpointQuotationAssistance}`)
        .pipe(map((res: Response<any>) => { return res }));
  }
  getExpeditionAssitance(request: CotizacionRequestAssistanceDto): Observable<CotizacionRequestAssistanceDto> {

    return this.http.post<Response<any>>(this.endpointExpeditionAssistance, request)
    .pipe(map((res: Response<any>) => { return res.data }));
  }


}
