import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Injectable } from '@angular/core';
import { Observable, from, lastValueFrom } from 'rxjs';
import { InterceptorService } from '../service/interceptor.service';

@Injectable()
export class TokenInterceptor implements HttpInterceptor {

    constructor(private interceptorService: InterceptorService) {
    }

    intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return from(this.handle(req, next))
    }

    async handle(req: HttpRequest<any>, next: HttpHandler) {
      const token = await this.interceptorService.acquireToken();

      const headers = req.clone(environment.production ? {
        headers: req.headers.set('Authorization', `Bearer ${token}`)
      } : {});

      return await lastValueFrom(next.handle(headers));
    }

}
