<ng-container *ngIf="isloggedIn; else loggedOutTemplate">
    <ngx-spinner color="#fff" bdColor="rgba(51,51,51,0.8)" size="medium" [type]="'ball-clip-rotate'">
        <p class="load">Cargando...</p>
    </ngx-spinner>
    <app-header-bc></app-header-bc>
    <bc-menu [logoutFunction]="logout" class="menu-app-showcase" state="close">
        <bc-menu-drawer>
            <bc-menu-item *ngFor="let menu of menuDinamic" [routerLink]="menu.path" text="{{ menu.text }}"
                iconName="{{ menu.iconName }}" id="{{ menu.id }}">
            </bc-menu-item>
        </bc-menu-drawer>
        <bc-menu-main></bc-menu-main>
        <div class="bc-content-body">
            <bc-breadcrumb></bc-breadcrumb>
            <router-outlet *ngIf="!isIframe"></router-outlet>
            <app-footer-bc></app-footer-bc>
        </div>
    </bc-menu>
</ng-container>
<ng-template #loggedOutTemplate>

    <div class="bc-container">
        <div class="bc-row">
            <div class="bc-col-12 menu">
                <bc-header class="header">
                    <bc-header-left logoName="bancolombia-horizontal" [menuInteraction]="false" [fixed]="false">
                    </bc-header-left>
                </bc-header>
            </div>
            <div class="bc-col-xs-12 bc-col-sm-12 bc-col-md-6 bc-col-lg-6">
                <img id="imagen inicio bancolombia" class="imagenInicio"
                    src="assets/img/Banner_Dektop.png" alt="">
            </div>
            <div class="bc-col-xs-12 bc-col-sm-12 bc-col-md-6 bc-col-lg-6 bc-align-self-center">
                <div class="bc-container">
                    <div class="button-container" >
                        <div class="container-right bc-container-32">
                            <h2>Portal autogestión</h2>
                            <h2>Seguros para Empleados</h2><br>
                            <p>Conoce los servicios digitales que tenemos para ti.</p>
                            <br>
                            <button *ngIf="isLoginButtonHidden" #button bc-button class="btnStart" aria-label="Ingresar"
                                role="button" id="login-button" (click)="loginPopup()">
                                Ingresar
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</ng-template>
