import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { endpoint } from 'src/environments/environment';
import ListResponse from '../dto/list-response.dto';
import { Observable, map } from 'rxjs';
import { VehicleClassModel } from '../model/VehicleClass.model';
import { VehicleBrandModel } from '../model/VehicleBrand.model';
import { VehicleLineModel } from '../model/VehicleLine.model';

@Injectable({
    providedIn: 'root'
})
export class VehicleInformationService {

    private endpointClases: string;
    private endpointFasecolda: string;

    constructor(private http: HttpClient) {
        this.endpointClases = endpoint.clasesvehiculo;
        this.endpointFasecolda = endpoint.fasecolda;
    }

    getClasses(): Observable<Array<VehicleClassModel>> {
        return this.http.get<ListResponse<VehicleClassModel>>(this.endpointClases)
            .pipe(map((res: ListResponse<VehicleClassModel>) => { return res.data }));
    }

    getBrands(modelo: string, codigoClase: string): Observable<Array<VehicleBrandModel>> {
        const params = {
            modelo: modelo
        }
        return this.http.get<ListResponse<VehicleBrandModel>>(`${this.endpointFasecolda}/codclasevehiculo/${codigoClase}`, { params: params })
            .pipe(map((res: ListResponse<VehicleBrandModel>) => { return res.data }));
    }

    getLinesByBrand(modelo: string, codigoClase: string, codigoMarca: string, descripcionMarca: string,): Observable<Array<VehicleLineModel>> {
        const params = {
            codClaseVehiculo: codigoClase,
            codMarca: codigoMarca,
            marca: descripcionMarca
        }
        return this.http.get<ListResponse<VehicleLineModel>>(`${this.endpointFasecolda}/lineavehiculos/${modelo}`, { params: params })
            .pipe(map((res: ListResponse<VehicleLineModel>) => { return res.data }));
    }

}
