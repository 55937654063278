import { NgModule } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { UbicationService } from './service/ubication.service';
import { HttpClientModule } from '@angular/common/http';
import { PersonalInformationService } from './service/personal-information.service';
import { VehicleInformationService } from './service/vehicle-information.service';
import { QuotationService } from './service/quotation.service';

@NgModule({
  declarations: [],
  imports: [
    CommonModule,
    HttpClientModule
  ],
  providers: [
    UbicationService,
    PersonalInformationService,
    VehicleInformationService,
    QuotationService,
    CurrencyPipe
  ]
})
export class CoreModule { }
