import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { endpoint } from 'src/environments/environment';
import ListResponse from '../dto/list-response.dto';
import { CityModel } from '../model/City.model';
import { StateModel } from '../model/State.model';

@Injectable({
    providedIn: 'root'
})
export class UbicationService {
    private endpointCity: string;
    private endpointState: string;

    constructor(private http: HttpClient) {
        this.endpointCity = endpoint.ciudades;
        this.endpointState = endpoint.estados;
    }

    getStatesByCountryId(countryId: number): Observable<Array<StateModel>> {
        return this.http.get<ListResponse<StateModel>>(`${this.endpointState}/${countryId}`)
            .pipe(map((res: ListResponse<StateModel>) => { return res.data }));
    }

    getStates(): Observable<Array<StateModel>> {
        return this.http.get<ListResponse<StateModel>>(this.endpointState)
            .pipe(map((res: ListResponse<StateModel>) => { return res.data }));
    }

    getCities(): Observable<Array<CityModel>> {
        return this.http.get<ListResponse<CityModel>>(this.endpointCity)
            .pipe(map((res: ListResponse<CityModel>) => { return res.data }));
    }

    getCitiesByEstadoId(stateId: number): Observable<Array<CityModel>> {
        return this.http.get<ListResponse<CityModel>>(`${this.endpointCity}/estado/${stateId}`)
            .pipe(map((res: ListResponse<CityModel>) => { return res.data }));
    }

}
