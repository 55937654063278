import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';
import { environment } from './environments/environment';

if (environment.production) {
  enableProdMode();
  if (environment.amb == 'QA' || environment.amb == 'PDN') {
    const body = document.body;
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML = '';
    if (environment.amb == 'QA') {
      script.src = 'assets/jsons/QA.js';
    }else{
      script.src = 'assets/jsons/PDN.js';
    }
    body.appendChild(script);
  }
}

platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));
